.arrowlinklist-module__qwbw0G__arrowListContainer {
  row-gap: var(--grid-gap-small);
  width: 100%;
  display: grid;
}

.arrowlinklist-module__qwbw0G__arrowListTitle {
  word-break: keep-all;
  white-space: nowrap;
  width: 100%;
}

.arrowlinklist-module__qwbw0G__arrowListTitle a, .arrowlinklist-module__qwbw0G__arrowListTitle span {
  color: var(--primary-2);
  font-family: var(--zbx-font-kievit);
  font-size: var(--font-size-2xl);
  font-style: normal;
  font-stretch: normal;
  font-weight: var(--font-weight-bold);
  letter-spacing: normal;
  word-wrap: break-word;
  white-space: wrap;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  width: 100%;
  line-height: normal;
  -webkit-text-decoration: none;
  transition: all .1s ease-in-out;
}

.arrowlinklist-module__qwbw0G__arrowListTitle:hover a {
  border-bottom: 4px solid var(--primary-1);
  width: -moz-fit-content;
  width: fit-content;
  transition: all .1s ease-in-out;
}

.arrowlinklist-module__qwbw0G__mainMiddleColumn .arrowlinklist-module__qwbw0G__leftColumn .arrowlinklist-module__qwbw0G__middleColumn .arrowlinklist-module__qwbw0G__rightColumn {
  width: -moz-fit-content;
  width: fit-content;
}

.arrowlinklist-module__qwbw0G__mainMiddleColumn .arrowlinklist-module__qwbw0G__leftColumn .arrowlinklist-module__qwbw0G__columnTitle {
  font-size: 24px;
}

.arrowlinklist-module__qwbw0G__arrowListChild {
  grid-column: span 1;
  margin-right: 10px;
}

.arrowlinklist-module__qwbw0G__arrowListChild a {
  height: 30px;
}

.arrowlinklist-module__qwbw0G__arrowLinkListChildAutoHeight a {
  height: auto;
}

.solutions-module__Sr_UCG__solutionsContainer {
  width: 100%;
}

.solutions-module__Sr_UCG__mainRightColumnTitle {
  background-color: #fff;
}

.solutions-module__Sr_UCG__leftColumn, .solutions-module__Sr_UCG__rightColumn {
  width: -moz-fit-content;
  width: fit-content;
}

.solutions-module__Sr_UCG__mainRightColumnTitle h5 {
  color: var(--primary-2);
  font-family: var(--zbx-font-kievit);
  font-size: var(--font-size-2xl);
  letter-spacing: normal;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  line-height: normal;
}

.solutions-module__Sr_UCG__mainRightColumn a {
  height: -moz-fit-content;
  height: fit-content;
}

.simpletextbox-module__OdFI2G__simpleTextTitle {
  margin: 0;
}

.simpletextbox-module__OdFI2G__simpleTextBoxTitle {
  color: var(--primary-2);
  letter-spacing: normal;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  width: -moz-fit-content;
  width: fit-content;
  font-family: KievitOT, Helvetica Neue, Arial, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  line-height: normal;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: all .1s ease-in-out;
  position: relative;
}

.simpletextbox-module__OdFI2G__simpleTextBoxTitleGold > a:first-child:after {
  background-color: var(--primary-1);
}

.simpletextbox-module__OdFI2G__simpleTextBoxTitleBlack > a:first-child:after {
  background-color: var(--primary-2);
}

.simpletextbox-module__OdFI2G__simpleTextBoxTitle > a:first-child:after {
  content: "";
  width: 100%;
  height: 0;
  transition: height .1s ease-in-out;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.simpletextbox-module__OdFI2G__simpleTextBoxTitle > a:first-child:hover:after {
  height: 4px;
  transition: height .1s ease-in-out;
}

.simpletextbox-module__OdFI2G__simpleTextBoxText {
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  letter-spacing: normal;
  max-width: 100%;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.22;
}

.simpletextbox-module__OdFI2G__simpleTextBoxTextSM {
  font-size: 16px;
}

.simpletextbox-module__OdFI2G__simpleTextBoxTextMD {
  font-size: 18px;
}

.simpletextbox-module__OdFI2G__simpleTextBoxTextLG {
  font-size: 20px;
}

